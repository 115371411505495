/* Tailwind CSS Directives */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Importing Fonts */
/* Header Font */
@import url("https://fonts.googleapis.com/css2?family=Lexend+Giga:wght@100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap");
/* Body Font */
@import url("https://fonts.googleapis.com/css2?family=Hind:wght@300;400;500;600;700&display=swap");
/* Logo Fonts */
/* BOSCOBOYS */
@import url("https://fonts.googleapis.com/css2?family=Amaranth:ital,wght@0,400;0,700;1,400;1,700&display=swap");
/* Distributors */
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap");

/* Global Styling */
body {
  @apply bg-light-background text-light-text dark:bg-dark-background dark:text-dark-text;
  font-family: "Montserrat", sans-serif;
}

/* Navbar Styling */
.navbar,
.navbar-small-screen,
.nav-link {
}

/* Small Screen Navbar Styling */
.navbar-small-screen {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10%);
}

.navbar-small-screen.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

/* Logo Styling */
.logo-container {
  position: relative;
  z-index: 50; /* Higher than navbar */
}

/* Navbar Dropdown */
.navbar-dropdown {
  z-index: 40; /* Lower than logo */
}

/* Navbar Link Hover Effect */
.nav-link:hover {
  color: theme("colors.light.primary"); /* Change color on hover */
}

.dark .nav-link:hover {
  color: theme("colors.dark.primary"); /* Change color on hover */
}

/* Specific Font Usage */
.header-font {
  font-family: "Montserrat", sans-serif;
}

.logo-boscoboys {
  font-family: "Amaranth", sans-serif;
}

.logo-distributors {
  font-family: "Roboto Condensed", sans-serif;
  @apply tracking-wider;
}

/* Font Face Definitions */
@font-face {
  font-family: "Neue Plak";
  src: url("./assets/fonts/Neue\ Plak\ Regular.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Neue Plak";
  src: url("./assets/fonts/Neue\ Plak\ Bold.ttf") format("truetype");
  font-weight: 700;
}

@font-face {
  font-family: "Neue Plak";
  src: url("./assets/fonts/Neue\ Plak\ Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Neue Plak";
  src: url("./assets/fonts/Neue\ Plak\ SemiBold.ttf") format("truetype");
  font-weight: 500;
}

/* Body Font Styles */
.body-font {
  font-family: "Neue Plak", sans-serif;
  @apply tracking-wide;
}

.body-font-regular {
  font-family: "Neue Plak", sans-serif;
  font-weight: 400;
  @apply tracking-wide;
}

.body-font-bold {
  font-family: "Neue Plak", sans-serif;
  font-weight: 700;
  @apply tracking-wide;
}

.body-font-light {
  font-family: "Neue Plak", sans-serif;
  font-weight: 300;
  @apply tracking-wide;
}

.body-font-semibold {
  font-family: "Neue Plak", sans-serif;
  font-weight: 500;
  @apply tracking-wide;
}

/* Animations */
.fade-in {
  opacity: 0;
  animation: fadeIn 1s forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

/* Image Effects */
.grayscale {
  filter: grayscale(100%);
}

/* Custom Scrollbar Styles for WebKit Browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 13px;
  height: 13px;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
  border: 3px solid transparent;
  background-clip: padding-box;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

/* Custom Scrollbar Styles for Firefox */
scrollbar-width: thin;
scrollbar-color: #888 #f1f1f1;

/* Marker Colouring */
.marker\:text-light-accent::marker {
  color: theme("colors.light.accent");
}

.dark .marker\:text-dark-accent::marker {
  color: theme("colors.dark.accent");
}
